import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import Header from './Header'
import Footer from './Footer'
import Home from './home/Home'
import Products from './products/Products';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}));

export default function App (props) {

    const loc = props.location.pathname.split('/')[1]
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="app">
            <Header loc={loc} />
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/productos" component={Products} exact={true} />
            </Switch>
            <Footer />
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
            >
                <div style={modalStyle} className={classes.paper}>
                    <picture>
                        <img style={{ height: '6rem' }} src="/images/logo.png" alt="Matanga"/>
                    </picture>
                    <h2 style={{ textAlign: 'center', fontSize: '2.5rem', textTransform: 'uppercase', color: '#2B2623', padding: '1rem 1.5rem', borderBottom: '1px solid #FFB500', marginTop: '1rem' }} id="simple-modal-title">¿Eres mayor de edad?</h2>
                    <ul className="modal-options">
                        <button onClick={handleClose}>Si</button>
                        <a style={{ marginLeft: '1.5rem' }} href="https://web.facebook.com/mezcalmatanga">No</a>
                    </ul>
                </div>
            </Modal>
        </div>
    )
}