import React from 'react'

export default function Products () {

    return (
        <div className="products">
            <div className="products__container">
                <h1>Conoce todos nuestros productos</h1>
                <picture>
                    <img src="/images/pres/1.png" alt="1"/>
                </picture>
                <picture>
                    <img src="/images/pres/2.png" alt="2"/>
                </picture>
                <picture>
                    <img src="/images/pres/3.png" alt="3"/>
                </picture>
                <picture>
                    <img src="/images/pres/4.png" alt="4"/>
                </picture>
                <picture>
                    <img src="/images/pres/5.png" alt="5"/>
                </picture>
                <picture>
                    <img src="/images/pres/6.png" alt="6"/>
                </picture>
                <picture>
                    <img src="/images/pres/7.png" alt="7"/>
                </picture>
                <picture>
                    <img src="/images/pres/8.png" alt="8"/>
                </picture>
                <picture>
                    <img src="/images/pres/9.png" alt="9"/>
                </picture>
                <picture>
                    <img src="/images/pres/10.png" alt="10"/>
                </picture>
                <picture>
                    <img src="/images/pres/11.png" alt="11"/>
                </picture>
            </div>
        </div>
    )
}