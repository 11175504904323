import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function Footer () {

    return (
        <footer>
            <div className="footer__bottom">
                <picture>
                    <img src="/images/logo.png" alt="Matanga"/>
                </picture>
                <ul>
                    <IconButton component="a" target="_blank" href="https://www.facebook.com/mezcalmatanga" rel="noopener noreferrer" size="medium">
                        <FacebookIcon style={{ fontSize: 25, color: '#2B2623' }} />
                    </IconButton>
                    <IconButton component="a" target="_blank" href="https://twitter.com/home" rel="noopener noreferrer" style={{ marginLeft: '2rem' }} size="medium">
                        <TwitterIcon style={{ fontSize: 25, color: '#2B2623' }} />
                    </IconButton>
                    <IconButton component="a" target="_blank" href="https://www.instagram.com/mezcalmatanga" rel="noopener noreferrer" style={{ marginLeft: '2rem' }} size="medium">
                        <InstagramIcon style={{ fontSize: 25, color: '#2B2623' }} />
                    </IconButton>
                </ul>
                <div className="footer__bottom-container">
                    <p>&copy; 2019. All Rights Reserved.</p>
                    <Link to="/aviso-privacidad">Aviso de privacidad</Link>
                </div>
            </div>
        </footer>
    )
}