import React from 'react'
import { Link } from 'react-router-dom';

export default function Header (props) {

    const {loc} = props

    return (
        <header>
            <picture>
                <img src="/images/logo.png" alt="Matanga"/>
            </picture>
            <ul>
                <Link className={loc === '' || loc === undefined ? 'active' : ''} to="/"><span>Inicio</span></Link>
                <Link className={loc === 'proceso' ? 'active' : ''} to="/proceso"><span>Proceso</span></Link>
                <Link className={loc === 'productos' ? 'active' : ''} to="/productos"><span>Productos</span></Link>
                <Link className={loc === 'contacto' ? 'active' : ''} to="/contacto"><span>Contacto</span></Link>
            </ul>
        </header>
    )
}