import React, { useState } from 'react';
import firebase from '../../firebase'

export default function Home() {

    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')
    const [message,setmessage] = useState('')
    const [selected,setSelected] = useState('/images/matanga1.png')

    return (
        <div className="home">
            <picture className="home__cover">
                
            </picture>
            <div className="home__history">
                <div className="home__history-container">
                    <picture>
                        <img src={selected} alt="Botella"/>
                    </picture>
                    <div className="content">
                        <h1>Matanga, Mezcal 100% de Oaxaca</h1>
                        <p>Cuenta la leyenda la historia de Matanga, una pequeña aldea en el lugar más remoto de Oaxaca, en la que sus habitantes nunca han dormido. Ellos relatan que por las noches, las cuales parecen durar un sexenio,  las sombras del monstro del mezcal son imposibles de evitar porque, a pesar de no verlo, su gobierno nocturno siempre deja una huella de pobreza, escases y mucho rezago, con lo cual los habitantes de Matanga tienen que aprender a vivir, pero eso sí, sin dejar de producir su mágico mezcal.</p>
                        <p>Grupo Mezcalero Matanga junto con  Un Motivo Más A.C y Fondo Unido Mexico I.A.P. destinaran parte de las ventas de este mezcal al desarrollo económico, de educación y de salud de comunidades Oaxaqueñas, entre ellas Ejutla de crespo.</p>
                    </div>
                </div>
            </div>
            <div className="home__gallery">
                <div className="home__gallery-container">
                    <picture></picture>
                    <picture onMouseOver={() => setSelected("/images/matanga1.png")}>
                        <img src="/images/matanga1.png" alt="Matanga 1"/>
                    </picture>
                    <picture onMouseOver={() => setSelected("/images/matanga2.png")}>
                        <img src="/images/matanga2.png" alt="Matanga 2"/>
                    </picture>
                    <picture onMouseOver={() => setSelected("/images/matanga3.png")}>
                        <img src="/images/matanga3.png" alt="Matanga 3"/>
                    </picture>
                    <picture></picture>
                </div>
            </div>
            <div className="home__order">
                <div className="home__order-container">
                    <div className="text">
                        <h3>Realiza tu pedido</h3>
                        <p>Envíanos cuantas botellas necesitas y tus datos de contacto.</p>
                    </div>
                    <div className="input-container">
                        <label>Cantidad de Botellas</label>
                        <input placeholder="Cantidad de Botellas" type="number"/>
                    </div>
                    <div className="input-container">
                        <label>Correo Electrónico</label>
                        <input placeholder="Correo Electrónico" type="email"/>
                    </div>
                    <div className="input-container">
                        <button>Enviar solicitud</button>
                    </div>
                </div>
            </div>
            <div className="home__description">
                <div className="home__description-container">
                    <picture>
                        <img src="/images/foto.jpeg" alt="Matanga"/>
                    </picture>
                    <div className="content">
                        <h2>Agaves Silvestres</h2>
                        <p>Producción de mezcal silvestre; viene de agaves que crecen en los monte, gracias a la polinización de esfingidos, murcielagos, abejas y colibries. hay una gran variedad de especias de agave silvestre, el estado de oaxaca tiene l amayor biodivarsidad de estos, algunos agaves tardan largos periodos de maduración, hay especies que llegan a tardar hasta 3 decadas. sin embargo la producción y oferta del mezcal silvestre no puede satisfacer el ritmo del consumo industrial, los tiempos de la naturaleza son los que rigen la producción de este. la jima(corte) de los agaves silvestres no es algo sencillo ya que implica la búsqueda de los agaves en montes, sacarlos de la tierra, cortarlos y trasladarlos al palenque.</p>
                    </div>
                </div>
            </div>
            <section id="contacto" className="home__contact">
                <div className="home__contact-container">
                    <div className="home__contact-middle-container">
                        <form onSubmit={e => e.preventDefault() && false} >
                            <div className="home__input-container">
                                <label>Nombre</label>
                                <input value={name} onChange={e => setname(e.target.value)} type="text" />
                            </div>
                            <div className="home__input-container">
                                <label>Correo Electrónico</label>
                                <input value={email} onChange={e => setemail(e.target.value)} type="email" />
                            </div>
                            <div className="home__input-container">
                                <label>Teléfono (Opcional)</label>
                                <input value={phone} onChange={e => setphone(e.target.value)} type="text" />
                            </div>
                            <div className="home__input-container">
                                <label>Mensaje</label>
                                <textarea value={message} onChange={e => setmessage(e.target.value)}></textarea>
                            </div>
                            <button onClick={handleSubmit} type="submit">Enviar</button>
                        </form>
                    </div>
                    <div className="home__contact-middle-container">
                        <h4>Contáctanos</h4>
                        <span className="home__contact-phone"><i className="material-icons">mail</i>amigos@mezcalmatanga.mx</span>
                    </div>
                </div>
            </section>
        </div>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                const item = {
                    name,
                    email,
                    phone,
                    message,
                    status: 'noRead'
                }
                firebase.simpleAdd(item,'messages').then(() => {
                    alert('Un asesor se pondrá en contacto contigo')
                    setname('')
                    setemail('')
                    setphone('')
                    setmessage('')
                })
            } else {
                alert('Para continuar debes agregar nombre, email y mensaje')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}