import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

import moment from 'moment';

const config = {
    apiKey: "AIzaSyCUxv6FwUn2xoFJVnU2JCebmiFS5tmQgT0",
    authDomain: "mezcal-ff43c.firebaseapp.com",
    databaseURL: "https://mezcal-ff43c.firebaseio.com",
    projectId: "mezcal-ff43c",
    storageBucket: "mezcal-ff43c.appspot.com",
    messagingSenderId: "881547119418",
    appId: "1:881547119418:web:320f3c03aae752b67bde03"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
        this.firestore = app.firestore()
        this.storage = app.storage()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
    }
    linkLogin(email) {
        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: 'https://uif-notaria-98-y-24.firebaseapp.com/',
            // This must be true.
            handleCodeInApp: true
        }
        return this.auth.sendSignInLinkToEmail(email,actionCodeSettings).then(() => {
            window.localStorage.setItem('emailForSignIn', email);
            alert('Revisa tu correo electrónico para completar tu inicio de sesión')
        }).catch(error => {
            alert(error.message)
        })
    }

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
        const timestamp = moment().valueOf()
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		}).then(() => {
            return this.firestore.doc(`users/${this.auth.currentUser.uid}`).set({
                name,
                id: this.auth.currentUser.uid,
                isActive: false,
                email,
                password,
                timestamp,
                rol: 'user'
            })
        })
    }

    userValidator(id) {
        return new Promise(resolve => {
            this.firestore.doc(`users/${id}`).onSnapshot(snap => {
                const user = snap.data();
                resolve(user)
            });
        });
    }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
    }

    async uploadDocumentFile(col,doc,index,file) {
        const uploadFile = (file,name,folder) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection(col).doc(doc)
        const url = await uploadFile(file,index,doc)
        return ref.update({ [index]: url })
    }

    async addImage(file,productId) {
        const uploadFile = (file,name,folder) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection('images').doc()
        const url_1 = await uploadFile(file, ref.id, 'products');
        const item = {
            file: url_1,
            productId,
            id: ref.id,
            timestamp: moment().valueOf()
        };
        return ref.set(item);
    }

    async addAnnex (id,index,item,file) {
        const uploadFile = (file,name) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref('operations/' + id).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection('operations').doc(id).collection(index).doc()
        const url = await uploadFile(file,ref.id)
        item.file = url
        item.id = ref.id
        return ref.set(item)
    }

    async addApperealFile (id,index,file) {
        const uploadFile = (file,name) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref('appearing/' + id).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection('appearing').doc(id)
        const url = await uploadFile(file,index)
        return ref.update({ [index]: url })
    }

    setReviewerCaseFile (id,userId) {
        if (userId === this.auth.currentUser.uid) {
            return this.firestore.collection('case-file').doc(id).update({ reviewerId: this.auth.currentUser.uid })
        } else {
            alert('El usuario que crea el expediente no puede ser el mismo que revisa')
        }
    }

    getCollectionOnce(col) {
        const data = this.firestore.collection(col).get()
        return data
    }
    getCollectionRealTime(col) {
        const data = this.firestore.collection(col)
        return data
    }
    getDocumentOnce(col,doc) {
        const data = this.firestore.collection(col).doc(doc).get()
        return data
    }
    getDocumentRealTime(col,doc) {
        const data = this.firestore.collection(col).doc(doc)
        return data
    }

    update(col,id,index,value) {
        return this.firestore.collection(col).doc(id).update({ [index]: value });
    }
    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        await ref.set(item);
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        await movesRef.set(move);
        return ref.id
    }
    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    async deleteOperationFile(col,doc,annex,id,data) {
        const ref = this.firestore.collection(col).doc(doc).collection(annex).doc(id)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col:`/${col}/${doc}/${annex}`,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: id,
            userId: this.auth.currentUser.uid,
            data
        }
        return movesRef.set(move);
    }
}

export default new Firebase()